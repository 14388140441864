import { maxMedia, MaxWidth, minMedia } from "../utils/style";
import { ColorDividerLeftRight } from "./color-divider";
import React from "react";
import styled from "styled-components";
import { minBy } from "lodash";

const Left = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1.5rem;

  ${minMedia.tablet`
    padding-right: 3rem;
  `}
`;

const Inner = styled.div`
  display: flex;
  padding: 1rem;
  .left {
    flex: 1 1 40%;
    padding-left: 5rem;
  }

  .right {
    flex: 1 1 60%;
  }

  ${maxMedia.tablet`
    flex-direction: column;
    padding: 3rem;
    .left {
      padding: 0rem;
    }
  `}
`;

export const PodcastHero = () => {
  return (
    <MaxWidth>
      <Inner>
        <div className="left">
          <h1 style={{ marginTop: 0, maxWidth: 200 }}>Podcast</h1>
        </div>
        <div className="right">
          <p>Praxisnah und fundiert. Für Führungskräfte. Kommunikation und Führung erfolgreich gestalten.</p>
          <p><img width={140} src={require("../assets/podcast-logo.png")} /></p>
          <a href="https://verhalten-gestalten.de" target="_blank">
            → Mehr dazu
          </a>
        </div>
      </Inner>
    </MaxWidth>
  );

  return (
    <ColorDividerLeftRight
      prc={0.36}
      left={
        <Left>
          <img width={180} src={require("../assets/podcast-logo.png")} />
        </Left>
      }
      right={
        <div style={{ padding: "3rem 1rem 3rem 3rem", maxWidth: 550 }}>
          <h4>Mehr zu diesen Themen hören Sie auch in meinem</h4>
          <h1>Podcast</h1>
          <p>Praxisnah und fundiert. Für Führungskräfte. Kommunikation und Führung erfolgreich gestalten.</p>
          <a href="https://verhalten-gestalten.de" target="_blank">
            → Mehr dazu
          </a>
        </div>
      }
    />
  );
};
