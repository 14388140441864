import styled from "styled-components";
import React from "react";
import { ThinText } from "./styles/Typography";
import { EasyGrid, Spacer } from "./styles/EasyGrid";
import { Button } from "./styles/LinkButton";
import { maxMedia, MaxWidth, theme } from "../utils/style";
import { ColorDividerLeftRight } from "./color-divider";
import { graphql, Link, useStaticQuery } from "gatsby";
import { observer, useObservable } from "mobx-react-lite";
import { Modal, ModalInner } from "./Modal";
import RTF from "./rtf";
import { VideoHover } from "./VideoHover";
import ReactMarkdown from "react-markdown";

const Div = styled.div`
  color: white;
`;

export const Hero2nd = observer((props: any) => {
  const data = useStaticQuery(graphql`
  {
    speaker_intro: contentfulHtml(uid: { eq: "speaker-intro" }) {
      content {
        json
      }
    }
  }
`);

  const store = useObservable({ showMenu: false });
  return (
    <Div>
      <ColorDividerLeftRight
        prc={0.45}
        leftColor={theme.primaryColor}
        rightColor={theme.primaryColorLight}
        left={
          <div style={{ paddingRight: "4rem", paddingBottom: "1rem" }}>
            <RTF content={data.speaker_intro.content.json} />
            <a className="none" href="javascript:;" onClick={() => (store.showMenu = true)}>
              → Mehr dazu
            </a>
            {store.showMenu && (
              <Modal>
                <VideoHover onClose={() => (store.showMenu = false)}>
                  <ModalInner>
                    <div className="content">
                      <ReactMarkdown>{`# Winfried Neun

Winfried Neun, geboren 1962 in Konstanz, absolvierte eine Banklehre bei der Sparkasse Bodensee-Konstanz und studierte Wirtschaftswissenschaften und Psychologie an der Universität Konstanz sowie an der Fachhochschule für Wirtschaft in Pforzheim. Nach erfolgreichem Abschluss seines Studiums absolvierte er eine Ausbildung zum PR-Berater nach den Richtlinien der Deutschen Public Relations Gesellschaft e. V. (DPRG). In Ergänzung dazu bildete er sich im Rahmen eines Fernlehrgangs in der Fachrichtung Journalismus an der Freien Journalistenschule fort. Des Weiteren studierte er an der Fernuniversität Hagen und absolvierte 2022 sein Masterstudium Mediation erfolgreich. Seit 2014 ist er Kuratoriumsmitglied an der HTWG in Konstanz sowie Gastprofessor für Wirtschaftspsychologie. Der Unternehmens- und Innovationscoach mit psychologischem Schwerpunkt lebt am Bodensee und hat drei Kinder.  

Zu Beginn seiner Karriere nahm Neun fünf Jahre Führungsaufgaben in der Dentalbranche in den Bereichen Marketing, Vertrieb und Geschäftsführung wahr. Anschließend gründete er 1991 die Kommunikations- und Managementberatungsgesellschaft K.O.M. GmbH, deren geschäftsführender Gesellschafter er bis heute ist. 

Als internationaler Coach und Berater ist Neun von Politik, Wirtschaft und Verbänden als Ratgeber für die professionelle Gestaltung von Veränderungen regelmäßig gefragt. Seine Schwerpunkte liegen in der professionellen Gestaltung von Veränderungsprozessen, insbesondere in den Bereichen Unternehmensführung, Strategie und Personal/Organisation. 

Zudem wurde Winfried Neun wiederholt seit 1998 vom Bundesverband Deutscher Unternehmensberater e. V. (BDU) als Certified Management Consultant (CMC) und 2023 für sein Lebenswerk ausgezeichnet.

Neben seinen unternehmerischen Tätigkeiten hat sich Winfried Neun ebenfalls als Autor und Publizist etabliert.
`}</ReactMarkdown>
                    </div>
                  </ModalInner>
                </VideoHover>
              </Modal>
            )}
          </div>
        }
        right={
          <RightEasyGrid>
            <EasyGrid vert style={{ marginRight: "2rem", paddingBottom: "3rem" }}>
              <Spacer />
              <h1 style={{ lineHeight: 1.5 }}>
                Neuerscheinung jetzt <br />
                <b>kostenfrei bestellen</b>
                <br />
              </h1>
              <ThinText>
                <i>*Solange der Vorrat reicht</i>
              </ThinText>
              <Spacer />
            </EasyGrid>
            <EasyGrid vert>
              <Spacer />
              <img width={160} src={require("../assets/book.png")} alt="" />
              <Link to="bestellliste">
                <Button>Bestellen</Button>
              </Link>
              <Spacer />
            </EasyGrid>
          </RightEasyGrid>
        }
      />
    </Div>
  );
});

const RightEasyGrid = styled(EasyGrid)`
  padding-left: 2rem;
  flex-wrap: nowrap;
  height: 100%;

  ${maxMedia.tablet`
    flex-wrap: wrap;
  `}
`
