import React from "react";
import { ColorDividerLeftRight } from "./color-divider";
import styled from "styled-components";
import { maxMedia, MaxWidth } from "../utils/style";
import { useStaticQuery, graphql } from "gatsby";
import { observer, useObservable } from "mobx-react-lite";
import { Modal, ModalInner } from "./Modal";
import { VideoHover } from "./VideoHover";
import RTF from "./rtf";

const Inner = styled.div`
  display: flex;
  padding: 1rem;
  .left {
    flex: 1 1 40%;
    padding-left: 5rem;
  }

  .right {
    flex: 1 1 60%;
  }

  ${maxMedia.tablet`
    flex-direction: column;
    padding: 3rem;
    .left {
      padding: 0rem;
    }
  `}
`;

const Outer = styled.div`
  > *:nth-child(odd) {
    background: #eee;
  }
`;

export const IchIchIch = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSpIchMichMeine(sort: { fields: order, order: DESC }) {
        nodes {
          id
          title
          order
          content {
            json
          }
          shortText {
            shortText
          }
        }
      }
    }
  `);

  return (
    <Outer>
      {data.allContentfulSpIchMichMeine.nodes.map(x => (
        <IchBlock {...x} />
      ))}
    </Outer>
  );
};

const IchBlock = observer((props: {
  title: string,
  order?: number,
  shortText: any,
  content?: any
}) => {
  const store = useObservable({ showMenu: props.order === 0 });

  return (
    <>
      <MaxWidth id="referenzen">
        <Inner>
          <div className="left">
            <h1 style={{ marginTop: 0, maxWidth: 200 }}>{props.title}</h1>
          </div>
          <div className="right">
            <p>{props.shortText.shortText}</p>

            <a
              href="javascript:;"
              onClick={e => {
                e.preventDefault();
                store.showMenu = true;
              }}
            >
              → Mehr dazu
            </a>
          </div>
        </Inner>
      </MaxWidth>

      {store.showMenu && (
        <Modal>
          <VideoHover onClose={() => (store.showMenu = false)}>
            <ModalInner>
              <div className="content">
                <RTF content={props.content.json} />
              </div>
            </ModalInner>
          </VideoHover>
        </Modal>
      )}
    </>
  );
});
