import React from "react";
import { ThinText } from "./styles/Typography";
import styled from "styled-components";

const Div = styled.div`
  margin: 3rem 0;
  text-align: center;
`;

export const TheVoiceOfChange = () => {
  return (
    <Div id="philosophie">
      <h3>
        <b>The Voice of Change:</b>
      </h3>
      <h1>„Der Stimmungsmacher für erfolgreiche Veränderungsprozesse“</h1>
      <ThinText>(Dr. Meinrad Lugan, B. Braun Melsungen AG)</ThinText>

      <img src={require("../assets/brain-image.png")} alt="" />
    </Div>
  );
};
