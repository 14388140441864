import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { MediaLightbox } from "../pages/medien";
import { slugify } from "../utils/slugify";
import { maxMedia, MaxWidth } from "../utils/style";
import { EasyGrid, Spacer } from "./styles/EasyGrid";
import { Button } from "./styles/LinkButton";

interface Props {}

const Outer = styled.div`
  position: relative;
  background: #eee;
  padding-bottom: 2rem;

  &:before {
    content: "";
    position: absolute;
    bottom: top;
    width: 100%;
    height: 350px;
    background: #fff;
  }
`;

const Inner = styled.div`
  margin: 2rem 0;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  > * {
    margin: 0 0.5rem;
  }

  ${maxMedia.tablet`
    flex-direction: column;
  `}
`;

export const Medien = observer((props: Props) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulSpMedia(limit: 3, sort: {fields: order, order: ASC}) {
        nodes {
          title
          order
          thumb {
            fixed(height: 180, width: 300) {
              base64
              src
            }
          }
          videoUrl
          media {
            fixed(height: 180, width: 300) {
              base64
              src
            }
            id
            file {
              url
              fileName
              contentType
            }
          }
        }
      }
    }
  `);

  return (
    <Outer>
      <MaxWidth id="Medien">
        <Inner>
          <h4 style={{ marginTop: "0rem", marginBottom: "1rem" }}>
            Weitere Einblicke finden Sie in unserer
          </h4>
          <h2 style={{ marginBottom: "3rem" }}>Mediengalerie</h2>
          <Content>
            <MediaLightbox data={data} />
          </Content>
        </Inner>
        <EasyGrid>
          <Spacer />
          <Link to="/medien">
            <Button onClick={() => {}}>Weitere Medien</Button>
          </Link>
          <Spacer />
        </EasyGrid>
      </MaxWidth>
    </Outer>
  );
});

const BoxDiv = styled.div`
  background: #ddd;
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-bottom: 1rem;

  padding: 1rem 1rem 0 1rem;

  .text {
    height: 250px;
    overflow: hidden;
    margin-bottom: 1rem;

    h3 {
      font-size: 1.3rem;
    }

    p {
      font-size: 0.85rem;
      font-weight: normal;
    }
  }

  .img-container {
    margin: 0 -1rem 0 -1rem;
    position: relative;
    height: 200px;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ${maxMedia.tablet`
    width: auto;
  `}
`;

const Box = (props: any) => {
  return (
    <BoxDiv>
      <Link className="none" to={`/vortrag/${slugify(props.title)}`}>
        <div className="text">
          <h5 style={{ fontWeight: "bold" }}>Vortrag {props.num}</h5>
          <h3>{props.shortTitle}</h3>
          <p>{props.extract.extract}</p>
        </div>
        <div className="img-container">
          <Img fluid={props.image.fluid} />
        </div>
      </Link>
    </BoxDiv>
  );
};
