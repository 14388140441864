import React from "react";
import styled from "styled-components";
import { maxMedia, MaxWidth } from "../utils/style";

export const ColorDivider = styled.div<{
  leftColor?: string;
  rightColor?: string;
  prc?: number;
}>`
  margin: 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(max(50vw - 600px, 0px) + ${p => p.prc} * min(1200px, 100vw));
    background: ${p => p.leftColor ?? "white"};
    transform: skew(-12deg);
    transform-origin: top;
    ${maxMedia.tablet`
      display: none;
    `}
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: ${p => p.rightColor ?? "white"};
    transform-origin: top;
  }

  ${maxMedia.tablet`
    .left {
      background: ${p => p.leftColor};
      margin: -1rem -1rem 0 -1rem;
      padding: 2rem 2rem 1rem 2rem;
    }
  `}
`;

const Inner = styled.div<{ prc: number }>`
  position: relative;
  display: flex;
  > *:nth-child(1) {
    flex: 1 1 ${p => p.prc * 100}%;
  }

  > *:nth-child(2) {
    flex: 1 1 ${p => (1 - p.prc) * 100}%;
  }

  ${maxMedia.tablet`
    flex-direction: column;
  `}
`;

export const ColorDividerLeftRight = (props: {
  left: JSX.Element;
  right: JSX.Element;
  leftColor?: string;
  rightColor?: string;
  prc?: number;
}) => {
  return (
    <ColorDivider prc={props.prc ?? 0.5} leftColor={props.leftColor} rightColor={props.rightColor}>
      <MaxWidth>
        <Inner prc={props.prc}>
          <div className="left">{props.left}</div>
          <div className="right">{props.right}</div>
        </Inner>
      </MaxWidth>
    </ColorDivider>
  );
};
