import React from "react";
import { MaxWidth, theme } from "../utils/style";
import { ColorDivider, ColorDividerLeftRight } from "./color-divider";
import styled from "styled-components";

const Div = styled.div`
  padding: 1rem 0;
  text-align: center;

  img {
    margin-bottom: 0.5rem;
  }
  
`;

export const NeunsightInfo = () => {
  return (
    <MaxWidth style={{ background: "#F3F4FA", paddingTop: "1rem" }}>
      <ColorDividerLeftRight
        prc={0.5}
        leftColor={"#F3F4FA"}
        rightColor={"#F3F4FA"}
        left={
          <Div>
            <a href="https://neunsight.de">
              <img width={350} src={require("../assets/neunsight-logo.png")} alt="" />
            </a>
            <p>Wirtschaftspsychologie und Management</p>
            <p style={{ marginTop: "-1.5rem" }}>Herausgegeben von Winfried Neun</p>
          </Div>
        }
        right={
          <Div>
            <a href="https://verhalten-gestalten.de">
              <img width={350} src={require("../assets/vg-logo.png")} alt="" />
            </a>
            <p>Ziele erreichen mit Winfried Neun</p>
          </Div>
        }
      />
    </MaxWidth>
  );
};
