import { graphql, Link, useStaticQuery } from "gatsby";
import Plyr from "plyr";
import * as _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import styled, { css } from "styled-components";
import { defaultPodcastUrls } from "../utils/const";
import { centerFlex, maxMedia, MaxWidth } from "../utils/style";
import { uiRegistry } from "../utils/ui-registry";
import RTF from "./rtf";
import { EasyGrid, Spacer } from "./styles/EasyGrid";
import { Button, LinkButton } from "./styles/LinkButton";
import { TiltImage } from "./tilt-image";
import { ThinText } from "./styles/Typography";
import Img, { GatsbyImageProps } from "gatsby-image";
import { slugify } from "../utils/slugify";

import { observer, useObservable } from "mobx-react-lite";
interface Props {}

const Outer = styled.div`
  position: relative;
  background: #eee;
  padding-bottom: 2rem;

  &:before {
    content: "";
    position: absolute;
    bottom: top;
    width: 100%;
    height: 450px;
    background: #fff;
  }
`;

const Inner = styled.div`
  margin: 3rem 0;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin: 0 0.5rem;
  }

  ${maxMedia.tablet`
    flex-direction: column;
  `}
`;

export const Vortragsreihen = observer((props: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSpVortrag(sort: { fields: order, order: ASC }) {
        nodes {
          id
          shortTitle
          title
          extract {
            extract
          }
          content {
            json
          }
          image {
            fluid(maxWidth: 450) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `);
  const store = useObservable({ showMore: false });

  const nodes = data.allContentfulSpVortrag.nodes;
  const len = nodes.length;
  const hasMore = len > 3;

  return (
    <Outer>
      <MaxWidth id="vortragsreihen">
        <Inner>
          <h2 style={{ textAlign: "center", marginBottom: "3rem" }}>Vorträge</h2>
          <Content>
            {_.range(Math.min(store.showMore ? 1000 : 3, len)).map((x) => (
              <Box num={x + 1} {...nodes[x]} />
            ))}
          </Content>
        </Inner>
        {hasMore && (
          <EasyGrid>
            <Spacer />
            <Button onClick={() => (store.showMore = !store.showMore)}>
              {" "}
              {store.showMore ? "Weniger" : "Weitere"}
            </Button>
            <Spacer />
          </EasyGrid>
        )}
      </MaxWidth>
    </Outer>
  );
});

const BoxDiv = styled.div`
  background: #ddd;
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-bottom: 1rem;

  padding: 1rem 1rem 0 1rem;

  .text {
    height: 250px;
    overflow: hidden;
    margin-bottom: 1rem;

    h3 {
      font-size: 1.3rem;
    }

    p {
      font-size: 0.85rem;
      font-weight: normal;
    }
  }

  .img-container {
    margin: 0 -1rem 0 -1rem;
    position: relative;
    height: 200px;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ${maxMedia.tablet`
    width: auto;
  `}
`;

const Box = (props: any) => {
  return (
    <BoxDiv>
      <Link className="none" to={`/vortrag/${slugify(props.title)}`}>
        <div className="text">
          <h5 style={{ fontWeight: "bold" }}>Vortrag vom {props.shortTitle}</h5>
          <h3>{props.title}</h3>
          <p>{props.extract.extract}</p>
        </div>
        <div className="img-container">
          <Img
            style={{ height: "100%" }}
            imgStyle={{
              objectFit: "cover",
              objectPosition: "top center",
            }}
            fluid={props.image.fluid}
          />
        </div>
      </Link>
    </BoxDiv>
  );
};
