import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import { AktuellerPodcast } from "../components/aktueller-podcast";
import { BooksCarousel } from "../components/books-carousel";
import { CoachTrainerRedner } from "../components/coach-trainer-redner";
import { ContactForm } from "../components/contact-form";
import { HeaderIndex } from "../components/header-general";
import { Kompetenzen } from "../components/kompetenzen";
import { Layout } from "../components/layout";
import SEO from "../components/seo";
import { EasyGrid } from "../components/styles/EasyGrid";
import { LinkButton } from "../components/styles/LinkButton";
import { TABLET_BR, MaxWidth, maxMedia } from "../utils/style";
import { useWindowSize } from "../utils/window-size";
import GatsbyImage from "gatsby-image";
import styled from "styled-components";
import { Hero2nd } from "../components/hero-2nd";
import { Vortragsreihen } from "../components/vortragsreihen";
import { PodcastHero } from "../components/podcast-hero";
import { IchIchIch } from "../components/ichichich";
import { TheVoiceOfChange } from "../components/the-voice-of-change";
import { NeunsightInfo } from "../components/neunsight-info";
import { Medien } from "../components/medien-preview";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="" keywords={[`gatsby`, `application`, `react`]} />
      <HeaderIndex />
      <Hero2nd />
      <Medien />
      <Vortragsreihen />
      <PodcastHero />

      <IchIchIch />

      <TheVoiceOfChange />
      <NeunsightInfo />

      {/* <Neunsight /> */}
      {/* <CoachTrainerRedner /> */}
      <BooksCarousel />
      <ContactForm />
    </Layout>
  );
};

export default IndexPage;
